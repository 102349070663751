import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import './HouseInfo.css';
import {BACKGROUND_COLORS, BASE_URL} from "../lib/Consts";
import {Button, Col, Row} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import GalleryComponent from "./components/GalleryComponent";
import {now} from "moment";
import Container from 'react-bootstrap/Container';

const I_BASE_URL = BASE_URL + "/reservations/gallery";
const PAYLOAD = {
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
    }
}

function Gallery() {

    const ctx = useContext(ContextProvider);
    const [payload, setPayload] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [modalProps, setModalProps] = useState({'show': false, 'photoKey': null, 'width': null, 'height': null, 'value': null});
    const [photoModal, setPhotoModal] = useState(<GalleryComponent show={modalProps['show']} photoKey={modalProps['photoKey']} width={modalProps['width']} height={modalProps['height']} value={modalProps['value']}/>);

    useEffect(() => {
        document.body.style.backgroundColor = BACKGROUND_COLORS["beach"];
        ctx.setNavBarView('beachNavBar');
        getData()
    }, []);

    useEffect(() => {
        setPhotoModal(<GalleryComponent show={modalProps['show']} photoKey={modalProps['photoKey']} width={modalProps['width']} height={modalProps['height']} value={modalProps['value']} modalClose={modalClose}/>);
    }, [modalProps]);

    function getData(){
        fetch(I_BASE_URL, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => res.json()
        ).then((response) => {
            setAdmin(response['admin']);
            setPayload(response['images']);
        }).catch(error => {
            setAdmin(false);
            setPayload([{'header': 'Error', 'text': 'Could not retrieve from the server, might be down'}])
        });
    }

    function modalClose(){
        setModalProps({'show': false, 'photoKey': null, 'width': null, 'height': null});
        getData();
    }

    const addPhoto = () => {
        setModalProps({'show': true, 'photoKey': null, 'width': null, 'height': null});
    }

    const viewPhoto = (photoKey, width, height, value) => {
        setModalProps({'show': true, 'photoKey': photoKey, 'width': width, 'height': height, 'value': value});
    }

    return (
        <div className="houseInfoRoot">
            {photoModal}
            <br/>
            <Button variant="primary" onClick={() => addPhoto()}>
                Add Photo
            </Button>
            <Container fluid>
                <Row>
                    {payload.map((item, index)=>{
                        return (
                            <Col key={index.toString()}>
                                <Card style={{ width: '18rem', height: 'auto', margin: "10px"}} onClick={() => viewPhoto(item['photoKey'], item['width'], item['height'], item['value'])}>
                                    <Card.Img style={{width: '100%'}} src={item['value']}/>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </div>
    );
}

export default Gallery;
