import React, {useContext, useEffect} from "react";
import {Button, Col, Row} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import './Home.css';
import AppNavBar from "../components/AppNavBar";
import {Link} from "react-router-dom";
import {ContextProvider} from "../lib/ContextProvider";
import {BACKGROUND_COLORS} from "../lib/Consts";

function Home() {

    const ctx = useContext(ContextProvider);

    useEffect(() => {
        ctx.setNavBarView('liamPortfolioBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["portfolio"];
    }, []);

    return (
        <div className="homeRoot">
            <Row className="g-4 text-center" xs={1} md={2} lg={3} xl={4} style={{margin: '25px'}}>
                <Col>
                    <Card className="card text-white bg-dark mb-3" style={{ width: '18rem'}}>
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + "/images/general/Profile.png"} />
                        <Card.Body>
                            <Card.Title style={{ fontSize: '32px'}}>About Me</Card.Title>
                            <Card.Text className="cardText">
                                Contact and background info
                            </Card.Text>
                            <Link to="/bio" className="stretched-link"/>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card className="card text-white bg-dark mb-3" style={{ width: '18rem', paddingBottom: '100px'}}>
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + "/images/general/Projects.png"} />
                        <Card.Body>
                            <Card.Title style={{ fontSize: '32px'}}>Projects</Card.Title>
                            <Card.Text className="cardText">
                                See the projects I've been working on and custom API's.
                            </Card.Text>
                            <Link to="/projects" className="stretched-link"/>
                        </Card.Body>
                    </Card>
                </Col>


                <Col>
                <Card className="card text-white bg-dark mb-3" style={{ width: '18rem', paddingBottom: '100px'}}>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + "/images/general/Controller.png"} />
                    <Card.Body>
                        <Card.Title style={{ fontSize: '32px'}}>Games</Card.Title>
                        <Card.Text className="cardText">
                            Fun stuff, check out some of the games I've created.
                        </Card.Text>
                        <Link to="/games" className="stretched-link"/>
                    </Card.Body>
                </Card>
                </Col>

                <Col>
                <Card className="card text-white bg-dark mb-3" style={{ width: '18rem', paddingBottom: '100px'}}>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + "/images/general/Server.png"} />
                    <Card.Body>
                        <Card.Title style={{ fontSize: '32px'}}>Server</Card.Title>
                        <Card.Text className="cardText">
                            Servers page
                        </Card.Text>
                        <Link to="/server" className="stretched-link"/>
                    </Card.Body>
                </Card>
                    </Col>
            </Row>
        </div>

    );
}

export default Home;
