import React, {useEffect, useState} from "react";
import {Button, FormCheck, Modal} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {BACKGROUND_COLORS, BASE_URL} from "../../lib/Consts";
import Rating from '@mui/material/Rating';

const R_BASE_URL = BASE_URL + "/reservations/reviews";
const PAYLOAD = {
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
    }
}

function ReviewComponent(props) {

    const [header, setHeader] = useState('');
    const [text, setText] = useState('');
    const [review, setReview] = useState(0);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [deleteEnabled, setDeleteEnabled] = useState(false);

    const [ratingValue, setRatingValue] = useState(0);

    const handleClose = () => {
        props['modalClose']();
    }

    useEffect(() => {
        setHeader(props['header']);
        setText(props['text']);
        setDate(props['date']);
        setName(props['name']);
        setReview(props['review']);
    }, [props]);

    const sendUpdate = (event) => {
        let payload = PAYLOAD;
        let url = R_BASE_URL;
        let body = {'header': header, 'text': text, 'rating': ratingValue}

        if (event === 'create') {
            payload['method'] = 'PUT';
            payload['body'] = JSON.stringify(body)
        }
        else {
            url = url + '/' + props['infoId'];
            payload['method'] = (event === 'delete') ? 'DELETE' : 'POST';
            payload['body'] = JSON.stringify({'header': header, 'text': text})
        }
        fetch(url, payload)
            .then((response) => {
                return response.json();
            }).catch(error => {
            console.log(error)
        });
        handleClose();
    }

    return (
        <Form>
            <Modal dialogClassName="blah" size='lg' show={props['show']} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{width: '75%'}}>{props['infoId'] ? 'Edit Review' : 'Add Review'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Rating
                        name="size-large"
                        value={ratingValue}
                        size="large"
                        onChange={(event, newValue) => {
                            setRatingValue(newValue);
                        }}
                    />
                    <Form.Control size="lg" type="text" placeholder="Summary Here" onChange={(e) =>{setHeader(e.target.value)}} value={header}/>
                    <br/>
                    <Form.Control as="textarea" placeholder="Details Here" rows={6} onChange={(e) =>{setText(e.target.value)}} value={text}/>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => sendUpdate(props['reviewId'] ? 'update' : 'create')}>
                        {props['infoId'] ? 'Update' : 'Create'}
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>
    );

}

export default ReviewComponent;