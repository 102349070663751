import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import Accordion from 'react-bootstrap/Accordion';
import './HouseInfo.css';
import {BACKGROUND_COLORS, BASE_URL} from "../lib/Consts";
import {Button} from "react-bootstrap";
import ReviewComponent from "./components/ReviewComponent";
import Form from 'react-bootstrap/Form';
import {now} from "moment";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Rating from "@mui/material/Rating";

const I_BASE_URL = BASE_URL + "/reservations/reviews";
const PAYLOAD = {
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
    }
}

function Reviews() {

    const ctx = useContext(ContextProvider);
    const [payload, setPayload] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [modalProps, setModalProps] = useState({'show': false, 'infoId': null, 'header': '', 'text': ''});
    const [reviewModal, setReviewModal] = useState(<ReviewComponent show={modalProps['show']} />);
    const [filter, setFilter] = useState(null);
    const [activeKey, setActiveKey] = useState(null);

    useEffect(() => {
        document.body.style.backgroundColor = BACKGROUND_COLORS["beach"];
        ctx.setNavBarView('beachNavBar');
        getData();
    }, []);

    useEffect(() => {
        setReviewModal(<ReviewComponent show={modalProps['show']}
                                        reviewId={modalProps['reviewId']}
                                        header={modalProps['header']}
                                        text={modalProps['text']}
                                        name={modalProps['name']}
                                        rating={modalProps['rating']}
                                        date={modalProps['date']}
                                        modalClose={modalClose}/>);
    }, [modalProps]);

    function getData(){
        fetch(I_BASE_URL, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => res.json()
        ).then((response) => {
            setAdmin(response['admin']);
            setPayload(response['data']);
        }).catch(error => {
            setAdmin(false);
            setPayload([{'header': 'Error', 'text': 'Could not retrieve from the server, might be down'}])
        });
    }

    function modalClose(){
        getData();
        setModalProps({'show': false, 'reviewId': null, 'header': '', 'text': '', 'name': '', 'rating': 0, 'date': now()});
    }

    const addReview = () => {
        setModalProps({'show': true, 'reviewId': null, 'header': '', 'text': '', 'name': '', 'rating': 0, 'date': now()});
    }

    const deleteReview = (reviewId) => {
        if (window.confirm("Are you sure you want to delete this review?")) {
            let url = I_BASE_URL;
            url = url + '/' + reviewId;
            let reqPayload = PAYLOAD;
            reqPayload['method'] = 'DELETE';
            // reqPayload['body'] = JSON.stringify({'review_id': reviewId})
            fetch(url, reqPayload);
            getData();
        }
    }

    return (
        <div className="houseInfoRoot">
            {reviewModal}
            <br/>
            <Button variant="primary" onClick={() => addReview()}>
                Add Review
            </Button>
            <br/>
            <br/>
            <br/>
            {payload.map((item,index)=>{
                return (
                    <div key={index.toString()}>
                        <Card sx={{ minWidth: 275, width: "90%", marginLeft: "auto", marginRight: "auto", marginBottom: "15px"}}>
                            <CardContent>
                                <Rating
                                    name="size-large"
                                    value={item['rating']}
                                    size="large"
                                    readOnly
                                />
                                <Typography sx={{ fontSize: 26}} gutterBottom>
                                    {item['header']}
                                </Typography>
                                <Typography sx={{ fontSize: 15, whiteSpace: 'pre-wrap'}} gutterBottom>
                                    {item['text']}
                                </Typography>
                                <Typography sx={{ fontSize: 15}} gutterBottom>
                                    -{item['name']} on {item['date']}
                                </Typography>
                                <Button className="btn btn-secondary btn-sm" onClick={() => deleteReview(item['review_id'])} disabled={!admin}>
                                    Delete
                                </Button>
                            </CardContent>
                        </Card>

                    </div>
                )
            })}

            {/*<Accordion className="accordionClass" alwaysOpen>*/}
            {/*    {payload.map((item,index)=>{*/}
            {/*        if(filter && (!item['header'].toLowerCase().includes(filter.toLowerCase()) && !item['text'].toLowerCase().includes(filter.toLowerCase()))){*/}
            {/*            return;*/}
            {/*        }*/}
            {/*        return (*/}
            {/*            <Accordion.Item eventKey={index.toString()} key={index.toString()}>*/}
            {/*                <Accordion.Header>{item['header']}</Accordion.Header>*/}
            {/*                <Accordion.Body><span style={{whiteSpace: "pre-line"}}>{item['text']}</span>*/}
            {/*                    <br/>*/}
            {/*                    <br/>*/}
            {/*                    <Button variant="primary" onClick={() => editInfo(item['header'], item['text'], item['order_id'])} disabled={!admin}>*/}
            {/*                        Edit*/}
            {/*                    </Button>*/}
            {/*                </Accordion.Body>*/}
            {/*            </Accordion.Item>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</Accordion>*/}

        </div>
    );
}

export default Reviews;
