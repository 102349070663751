
let IP_ADDRESS = "";
let PORT = "";
let SET = false;

if (!SET){
    SET = true;
    if (process.env.NODE_ENV === 'development'){
        IP_ADDRESS = "localhost";
        PORT = "8887";
    }else{
        IP_ADDRESS = "liamhenry.net";
        PORT = "8885"
    }
}

const BASE_URL = "https://" + IP_ADDRESS + ":" + PORT;
const WEB_SOCKET_BASE_URL = "wss://" + IP_ADDRESS + ":" + PORT;

const BACKGROUND_COLORS = {
    'portfolio': "#0f181c",
    'beach': "#97c7dc",
}

export {BASE_URL, WEB_SOCKET_BASE_URL, BACKGROUND_COLORS}
