import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import './Register.css';
import {Alert, Button, Card, Col, FloatingLabel, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";

import {BASE_URL, BACKGROUND_COLORS} from "../lib/Consts";
import {ContextProvider} from "../lib/ContextProvider";
import CustomModal from "./CustomModal";

const REGISTER_URL = BASE_URL + "/register";

function RegisterComponent() {

    const ctx = useContext(ContextProvider);
    const navigate = useNavigate();
    useEffect(() => {
        ctx.setNavBarView('liamPortfolioBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["portfolio"];
    }, []);

    const [values, setValues] = useState({reservation_access: false, server_access: false});
    const [showModal, setShowModal] = useState(false);
    const [spinnerHidden, setSpinnerHidden] = useState(true);
    const [modalText, setModalText] = useState({'title': 'Error', 'body': 'No error message set'});
    const [navigateToHome, setNavigateToHome] = useState(false);

    const handleClose = () => {
        setShowModal(false);
        setSpinnerHidden(true);
        if(navigateToHome){
            navigate('/');
        }
    }

    const onInput = (event) => {
        let newVal = event.target.value
        if (['reservation_access'].includes(event.target.name)) {
            newVal = !values[event.target.name]
        }
        if (['server_access'].includes(event.target.name)) {
            newVal = !values[event.target.name]
        }
        setValues(values => ({...values, [event.target.name]: newVal}))
        if (event.target.name === 'password') {
            // console.log(event);
            // TODO: set up password verification
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setSpinnerHidden(false);
            fetch(REGISTER_URL, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                },
                body: JSON.stringify(values)
            })
                .then(res => res.json())
                .then((response) => {
                    if ("error" in response) {
                        setModalText({'title': 'Error registering', 'body': response['error']});
                        setShowModal(true);
                        setNavigateToHome(false);
                    } else {
                        sessionStorage.setItem("liamPortfolioVerified", 'no');
                        setModalText({
                            'title': 'Registration Successful',
                            'body': 'Please validate your email when you can. ' +
                                'If selected permissions below it will take time to review'
                        })
                        setShowModal(true);
                        setNavigateToHome(true);
                    }
                })
                .catch(error => {
                    serverError(error)
                });
        }
    };

    function serverError(error) {
        setModalText({'title': 'Server error', 'body': error.toString()});
        setShowModal(true);
    }

    function modalBodyView() {
        if (modalText.title.toLowerCase().includes("error")) {
            return (<Alert variant="danger">
                <Alert.Heading>Error</Alert.Heading>
                <p>
                    {modalText['body']}
                </p>
            </Alert>);
        } else {
            return (modalText['body']);
        }
    }

    return (
        <div className="rootRegister">
            <CustomModal show={showModal} onClose={handleClose} footer={"okFooter"} title={modalText['title']} body={modalBodyView()}/>

            <Form className="form" noValidate onSubmit={handleSubmit}>
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} md="4" controlId="validationCustom01" className="mb-3" >
                        <FloatingLabel controlId="floatingInput" label="First Name">
                            <Form.Control name="first_name" placeholder="First name" required onChange={onInput} />
                        </FloatingLabel>

                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <FloatingLabel controlId="floatingInput" label="Last Name" className="mb-3" >
                            <Form.Control name="last_name" placeholder="Last name" required onChange={onInput} />
                        </FloatingLabel>
                    </Form.Group>

                </Row>
                <Row className="justify-content-md-center">

                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                        <FloatingLabel controlId="floatingInput" label="Username" className="mb-3">
                            <Form.Control name="username" placeholder="Username" required onChange={onInput}/>
                            <Form.Control.Feedback type="invalid">
                                Please choose a username.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                        <FloatingLabel controlId="floatingInput" label="Email Address" className="mb-3">
                            <Form.Control type="email" placeholder="Email" required name="email_address" onChange={onInput}/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid email
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                </Row>

                <Row className="justify-content-md-center">
                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                        <FloatingLabel controlId="floatingInput" label="Password" className="mb-3">
                            <Form.Control
                                name="password"
                                type="password"
                                required
                                onChange={onInput}
                            />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                        <FloatingLabel controlId="floatingInput" label="Re-Enter Password" className="mb-3">
                            <Form.Control type="password" name="password_verify" required onChange={onInput}/>
                        </FloatingLabel>
                    </Form.Group>
                </Row>

                <Row className="justify-content-md-center" style={{width:"95%", marginLeft: "auto", marginRight: "auto", backgroundColor: "#585b5e", maxWidth: "500px"}}>
                    <Col>
                        <div className="registerCard">
                            <h3 className="headerText">Beach House Access</h3>
                            <p className="baseText">Booking the beach house? Select below for this access</p>
                            <Form.Check className="checks" type="switch" id="custom-switch" name="reservation_access" onChange={onInput} />
                        </div>
                    </Col>

                </Row>
                <br/>
                <br/>

                <Row className="justify-content-md-center" style={{width:"95%", marginLeft: "auto", marginRight: "auto", backgroundColor: "#585b5e", maxWidth: "500px"}}>
                    <div className="registerCard">
                        <h3 className="headerText">Server Access</h3>
                        <p className="baseText">Need access to the server? Select below for this access</p>
                        <Form.Check className="checks" type="switch" id="custom-switch" name="server_access" onChange={onInput} />
                    </div>
                </Row>
                <br/>
                <br/>
                <br/>
                <br/>
                <Button className="registerSubmit" type="submit">
                    Submit Request
                    <Spinner
                        hidden={spinnerHidden}
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </Button>
            </Form>
        </div>
    );
}

export default RegisterComponent;
