
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import ContextProvider from "./lib/ContextProvider";

import Home from "./portfolio/Home";
import Bio from "./portfolio/Bio";
import AppNavBar from "./components/AppNavBar";
import Register from "./components/Register";
import Login from "./components/Login";
import Parking from "./components/Parking";
import Reservations from "./reservations/Reservations";
import House from "./reservations/House";
import HouseInfo from "./reservations/HouseInfo";
import EntitlementMonitor from "./entitlements/Monitor";
import Traffic from "./reservations/Traffic";
import Reviews from "./reservations/Reviews";
import Gallery from "./reservations/Gallery";
import Restroom from "./components/Restroom";
import Reset from "./components/Reset";
import ResetFinishComponent from "./components/ResetFinish";

function App() {
    return (
        <div className="App">
            <ContextProvider>
                <BrowserRouter>
                    <AppNavBar/>
                    <Routes>
                        <Route name="home" exact path="/" element={<Home />}/>
                        <Route name="bio" exact path="/bio" element={<Bio />}/>
                        <Route name="register" exact path="/register" element={<Register />}/>
                        <Route name="login" exact path="/login" element={<Login />}/>
                        <Route name="beach" exact path="/beach" element={<House />}/>
                        <Route name="gallery" exact path="/beach/gallery" element={<Gallery />}/>
                        <Route name="house_info" exact path="/beach/house" element={<HouseInfo />}/>
                        <Route name="reserve" exact path="/beach/reserve" element={<Reservations />}/>
                        <Route name="reviews" exact path="/beach/reviews" element={<Reviews />}/>
                        <Route name="traffic" exact path="/beach/traffic" element={<Traffic />}/>
                        <Route name="permissions" exact path="/permissions" element={<EntitlementMonitor />}/>
                        <Route name="parking" exact path="/parking" element={<Parking />}/>
                        <Route name="restroom" exact path="/poop" element={<Restroom />}/>
                        <Route name="reset" exact path="/reset" element={<Reset />}/>
                        <Route name="resetFinish" exact path="/resetP" element={<ResetFinishComponent />}/>
                    </Routes>
                </BrowserRouter>
            </ContextProvider>
        </div>
    );
}

export default App;
