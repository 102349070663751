import React, {useCallback, useContext, useEffect, useState} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import moment from "moment";
import {BASE_URL, BACKGROUND_COLORS} from "../lib/Consts";
import {Button, Modal} from "react-bootstrap";
import BookComponent from "./components/BookComponent";
import ModalView from "../components/ModalView";
import './Reservations.css';

require('react-big-calendar/lib/css/react-big-calendar.css');

const R_BASE_URL = BASE_URL + "/reservations";

const events = [
    {
        id: 'asdasdasdas',
        title: 'Board meeting',
        start: new Date(2023, 3, 17, 0, 0, 0),
        end: new Date(2023, 3, 19, 23, 59, 59),
        resourceId: 1,
        hexColor: "00FF00",
    },
]


function Reservations() {

    const localizer = momentLocalizer(moment);
    const [myEvents, setEvents] = useState(events);
    const [admin, setAdmin] = useState(false);
    const [modalProps, setModalProps] = useState({'show': false, 'reservationId': null});
    const [bookModal, setBookModal] = useState(<BookComponent show={modalProps['show']} reservationId={null} admin={false}/>);
    const [monthData, setMonthData] = useState([ moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]);

    const ctx = useContext(ContextProvider);
    useEffect(() => {
        document.body.style.backgroundColor = BACKGROUND_COLORS["beach"];
        ctx.setNavBarView('beachNavBar');
        pullReservationsData();
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = BACKGROUND_COLORS["beach"];
        pullReservationsData();
    }, [monthData]);

    function modalClose(){
        setModalProps({'show': false, 'reservationId': null, 'link': modalProps['link']});
        pullReservationsData();
    }

    function pullReservationsData() {
        let targetUrl = R_BASE_URL
        targetUrl = targetUrl + "?start=" + monthData[0] + "&end=" + monthData[1]
        fetch(targetUrl, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
            .then(res => res.json())
            .then((response) => {
                setAdmin(response['admin']);
                setEvents(response['reservations']);
            })
            .catch(error => {
                console.log('Put error handling here')
            })
    }

    function handleSelectEvent(e){
        setModalProps({'show': true, 'reservationId': e['id'] , 'start': null, 'end': null});
    }

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            setModalProps({
                'show': true,
                'reservationId': null,
                'start': start.toString(),
                'end': end.toString()});
        },
        [setEvents]
    )

    useEffect(() => {
        setBookModal(<BookComponent show={modalProps['show']}
                                    reservationId={modalProps['reservationId']}
                                    start={modalProps['start']}
                                    end={modalProps['end']}
                                    admin={admin}
                                    modalClose={modalClose}/>);
    }, [modalProps]);

    function eventStyleGetter(event, start, end, isSelected) {
        var backgroundColor = '#' + event.hexColor;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        }
    }

    const setMonth  = (e) => {
        setMonthData([ moment(e).startOf('month').format('YYYY-MM-DD'),
            moment(e).endOf('month').format('YYYY-MM-DD')]);
    }

    return (
        <div className="reservationsRoot">
            {bookModal}
            <div style={{height: 650, backgroundColor: "white"}}>
                <Calendar
                    localizer={localizer}
                    defaultView={Views.MONTH}
                    views={['month']}
                    events={myEvents}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={handleSelectEvent}
                    onSelectSlot={handleSelectSlot}
                    selectable
                    eventPropGetter={(eventStyleGetter)}
                    onNavigate={(e) => { setMonth(e) }}
                />
            </div>
        </div>
    );
}

export default Reservations;
