import React, {useContext, useEffect, useState} from "react";
import {useNavigate, Link} from "react-router-dom";
import {Alert, Button, Card, Col, FloatingLabel, Form, Modal, Row, Spinner} from "react-bootstrap";
import './Login.css';
import './Standard.css';
import {BASE_URL, BACKGROUND_COLORS} from "../lib/Consts";
import {ContextProvider} from "../lib/ContextProvider";
import CustomModal from "./CustomModal";

const LOGIN_URL = BASE_URL + "/login";

function LoginComponent() {

    const ctx = useContext(ContextProvider);
    const navigate = useNavigate();
    useEffect(() => {
        ctx.setNavBarView('liamPortfolioBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["portfolio"];
    }, []);

    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState();
    const [showModal, setShowModal] = useState(false);
    const [spinnerHidden, setSpinnerHidden] = useState(true);
    const [modalText, setModalText] = useState({'title': 'Error', 'body': 'No error message set'});

    const handleClose = () => {
        setShowModal(false);
        setSpinnerHidden(true);
    }

    const onInput = (event) => {
        setValues(values => ({...values, [event.target.name]: event.target.value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSpinnerHidden(false);
        fetch(LOGIN_URL, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify(values)
        })
            .then(res => res.json())
            .then((response) => {
                if ("error" in response) {
                    setModalText({'title': 'Error', 'body': response['error']})
                    setShowModal(true)
                } else {
                    // TODO: Add navigation to where user last was
                    ctx.setUsername(response['username']);
                    navigate('/');
                }
            })
            .catch(error => {
                setModalText({'title': 'Server error', 'body': error.toString()});
                setShowModal(true);
            });
    };

    function modalBodyView() {
        if (modalText.title.toLowerCase().includes("error")) {
            return (<Alert variant="danger">
                <Alert.Heading>{modalText['body']}</Alert.Heading>
                <p>
                    <Link to="/reset">Forgot password? Click Here To Reset</Link>
                </p>
            </Alert>);
        } else {
            return (modalText['body']);
        }
    }

    return (
        <div className="Form">
            <CustomModal show={showModal} onClose={handleClose} footer={"okFooter"} title={modalText['title']} body={modalBodyView()}/>
            <div className="test">
                <Form onSubmit={handleSubmit} style={{padding: "30px"}}>
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                            <FloatingLabel controlId="floatingInput" label="Username" className="mb-3">
                                <Form.Control name="username" placeholder="Username" required onChange={onInput}/>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} md="4" controlId="validationCustom04">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Password"
                                className="mb-3"
                            >
                                <Form.Control
                                    name="password"
                                    required
                                    type="password"
                                    onChange={onInput}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Button type="submit" as={Col} md="4" onClick={handleSubmit}>
                            Login
                            <Spinner
                                hidden={spinnerHidden}
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    </Row>

                </Form>
                <Row className="justify-content-md-center">
                    <Button href="/register" md="4" style={{ maxWidth: "90px", marginTop: '30px'}}>
                        Register
                    </Button>
                </Row>
            </div>

        </div>
    );


}

export default LoginComponent;
