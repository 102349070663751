import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import {BASE_URL} from "../lib/Consts";
import {Link, useNavigate} from "react-router-dom";
import {Alert, Button, Modal} from "react-bootstrap";

function ModalView(props) {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(props['show']);
    const [modalText, setModalText] = useState(props['text']); // {'title': 'Error', 'body': 'No error message set'}
    const [modalCloseCallback, setModalCloseCallback] = useState(props['link']);

    useEffect(() => {
        setShowModal(props['show']);
        setModalText(props['text']);
        setModalCloseCallback(props['link']);
    });

    const handleClose = () => {
        props['modalClose']();
        setShowModal(false);
        if (modalCloseCallback) {
            navigate(modalCloseCallback);
        }
    }

    function modalBodyView() {
        let view = (<p>{modalText['body']}</p>);
        if (modalText.title.toLowerCase().includes("not logged in")) {
            view = (
                <div>
                    <p>{modalText['body']}</p>
                    <Link to="/login">Login here</Link>
                </div>
            );
        }
        else if (modalText.title.toLowerCase().includes("authorization")) {

        }
        else if (modalText.title.toLowerCase().includes("error")) {
            view = (
                <Alert variant="danger">
                    <Alert.Heading>Oh Snap!</Alert.Heading>
                    <p>
                        Looks like there is a problem with the server. Please be patient until fixed.
                    </p>
                </Alert>
            );
        }
        return view
    }


    return (
        <div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalText['title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBodyView()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
}
export default ModalView;