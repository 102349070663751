import React, {useContext, useEffect} from "react";
import './Home.css';
import {ContextProvider} from "../lib/ContextProvider";
import {BACKGROUND_COLORS} from "../lib/Consts";

function Bio() {

    const ctx = useContext(ContextProvider);
    useEffect(() => {
        ctx.setNavBarView('liamPortfolioBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["portfolio"];

    }, []);

    return (
        <div>

        </div>

    );
}

export default Bio;
