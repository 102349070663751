import {useState, useContext, useEffect} from 'react';
import {ContextProvider} from "../lib/ContextProvider";
import {Link} from "react-router-dom";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";

import {BASE_URL} from "../lib/Consts";
const LOGOUT_URL = BASE_URL + "/logout";

function AppNavBar(props) {

    const ctx = useContext(ContextProvider);
    const [title, setTitle] = useState('Liam Henry');

    const updateTitle = (event) => {
        if(ctx.isMobile){
            setTitle(event.target.name);
        }
    }

    const logout = (event) => {
        let username = ctx.username;
        ctx.setUsername(null);
        ctx.setNavBarView('liamPortfolioBar');
        fetch(LOGOUT_URL, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({'username': username})
        })

    }

    function loginView(){
        if(ctx.username !== null){
            return (
                <Navbar className="justify-content-end">
                    <Nav>
                        <Nav.Link as={Link} to="/" onClick={logout}>Logout</Nav.Link>
                    </Nav>
                </Navbar>
            );
        }
        else{
            let registerLink = !ctx.isMobile ? <Nav.Link as={Link} to="/register">Register</Nav.Link> : <div/>
            return (
                <Navbar className="justify-content-end">
                    <Nav>
                        {registerLink}
                        <Nav.Link as={Link} to="/login">Login</Nav.Link>
                    </Nav>
                </Navbar>
            );
        }
    }

    let navBar;
    if(!ctx.navBarView || ctx.navBarView === 'liamPortfolioBar'){
        navBar = (
            <Navbar bg="light" variant="light" collapseOnSelect fixed='top' expand='sm'>
                <Container>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                    <Navbar.Brand href="/">{title}</Navbar.Brand>
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className="me-auto">
                            {title !== 'Liam Henry' && ctx.isMobile ? <Nav.Link as={Link} to="/" name='Liam Henry' eventKey="1" onClick={updateTitle}>Home</Nav.Link> : <div/>}
                            <Nav.Link as={Link} to="/bio" name='Bio' eventKey="2" onClick={updateTitle}>Bio</Nav.Link>
                            <NavDropdown title="Projects" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1" name='Projects' eventKey="3" onClick={updateTitle}>All Projects</NavDropdown.Item>
                                <NavDropdown.Item as={Link} eventKey="4"  to="/beach">Beach House</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2" eventKey="5" >Rust Admin</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3" eventKey="6" >Football Stats</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Games" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1" eventKey="7" >Avalon</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2" eventKey="8" >Pokemon</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3" eventKey="9" >Jeopardy</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#servers" name='Servers' eventKey="11" onClick={updateTitle}>Servers</Nav.Link>
                            <Nav.Link href="/permissions" name='Permissions' eventKey="12" onClick={updateTitle}>Permissions</Nav.Link>
                            <Nav.Link href="/parking" name='Parking' eventKey="13" onClick={updateTitle}>Parking</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    {loginView()}
                </Container>
            </Navbar>
        )
    }
    else if(ctx.navBarView === 'beachNavBar'){
        // TODO: Don't url link, change context instead, all controlled under /beach that way
        navBar = (
            <Navbar bg="light" variant="light" collapseOnSelect fixed='top' expand='sm'>
                <Container>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                    <Navbar.Brand as={Link} to="/beach">Beach House</Navbar.Brand>
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/" eventKey="14">Back to Portfolio</Nav.Link>
                            <Nav.Link as={Link} to="/beach/reserve" eventKey="15" name='Reserve' onClick={updateTitle}>Book</Nav.Link>
                            <Nav.Link as={Link} to="/beach/gallery" eventKey="16" name='Gallery' onClick={updateTitle}>Gallery</Nav.Link>
                            <Nav.Link as={Link} to="/beach/reviews" eventKey="17" name='Reviews' onClick={updateTitle}>Reviews</Nav.Link>
                            <Nav.Link as={Link} to="/beach/house" eventKey="18" name='House' onClick={updateTitle}>House Info</Nav.Link>
                            <Nav.Link as={Link} to="/beach/weather" eventKey="19" name='Weather' onClick={updateTitle}>Weather</Nav.Link>
                            {/*<Nav.Link as={Link} to="/beach/traffic" name='Traffic' onClick={updateTitle}>Traffic</Nav.Link>*/}
                            <Nav.Link as={Link} to="/beach/todo" eventKey="20" name='Todo' onClick={updateTitle}>Things To Do</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    {loginView()}
                </Container>
            </Navbar>
        )
    }

    return navBar;

}

export default AppNavBar;