import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import './Standard.css';
import {Alert, Button, Card, Col, FloatingLabel, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";

import {BASE_URL, BACKGROUND_COLORS} from "../lib/Consts";
import {ContextProvider} from "../lib/ContextProvider";
import CustomModal from "./CustomModal";

const RESET_URL = BASE_URL + "/reset";

function ResetComponent() {

    const ctx = useContext(ContextProvider);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState({'title': 'Error', 'body': 'No error message set'});
    const [values, setValues] = useState();

    useEffect(() => {
        ctx.setNavBarView('liamPortfolioBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["portfolio"];
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        fetch(RESET_URL, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify(values)
        })
            .then(res => res.json())
            .then((response) => {
                if ("error" in response) {
                    setModalText({'title': 'Error', 'body': response['error']})
                    setShowModal(true)
                } else {
                    // navigate('/');
                    setModalText({'title': 'Reset Request Sent', 'body': 'Please check your email for further instructions'})
                    setShowModal(true)
                }
            })
            .catch(error => {
                setModalText({'title': 'Server error', 'body': error.toString()});
                setShowModal(true);
            });
    };

    function modalBodyView() {
        if (modalText.title.toLowerCase().includes("error")) {
            return (<Alert variant="danger">
                <Alert.Heading>Error</Alert.Heading>
                <p>
                    {modalText['body']}
                </p>
            </Alert>);
        } else {
            return (modalText['body']);
        }
    }

    const handleClose = () => {
        setShowModal(false);
        navigate('/');
    }

    const onInput = (event) => {
        setValues(values => ({...values, [event.target.name]: event.target.value}))
    }

    return (
        <div className="Form">
            <CustomModal show={showModal} onClose={handleClose} footer={"okFooter"} title={modalText['title']}
                         body={modalBodyView()}/>
            <Form onSubmit={handleSubmit} style={{padding: "30px"}}>
                <Row className="justify-content-md-center">
                    <Form.Group as={Col} md="4" controlId="validationEmail">
                        <FloatingLabel controlId="floatingInput" label="Email Address" className="mb-3">
                            <Form.Control type="email" placeholder="Email" required name="email_address" onChange={onInput}/>
                            <Form.Control.Feedback type="invalid">
                                Email address registered with
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="justify-content-md-center">
                    <Button type="submit" as={Col} md="4" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Row>

            </Form>
        </div>
    );
}

export default ResetComponent;
