import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import {BACKGROUND_COLORS, BASE_URL} from "../lib/Consts";
import {Link} from "react-router-dom";
import {Alert} from "react-bootstrap";
import ModalView from "../components/ModalView";
import './Monitor.css';

const AUTH_URL = BASE_URL + "/auth";
const ACTION_URL = BASE_URL + "/auth/action";
const PAYLOAD = {
    credentials: 'include',
    method: 'GET',
    headers: {
        'Accept': 'application/json',
    }
}

function EntitlementMonitor() {

    const [authData, setAuthData] = useState({'user_permissions': [], 'apply_permissions': [], 'admin_access': []})
    const [modalProps, setModalProps] = useState({'show': false, 'text': {'title': 'Error', 'body': 'No error message set'}});
    const [modal, setModal] = useState(<ModalView show={modalProps['show']} text={modalProps['text']}/>);

    function modalClose(){
        setModalProps({'show': false, 'text': modalProps['text'], 'link': modalProps['link']});
    }

    const ctx = useContext(ContextProvider);
    useEffect(() => {
        ctx.setNavBarView('liamPortfolioBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["portfolio"];
        callUrl(AUTH_URL).then(
            function(response){
                setAuthData(response);
            }
        );
    }, [ctx]);

    function callUrl(url, body){
        let payload = PAYLOAD
        if (body){
            payload['method'] = 'POST'
            payload['body'] = JSON.stringify(body)
        }
        else{
            payload['method'] = 'GET'
            payload['body'] = null
        }
        return fetch(url, payload)
            .then((response) => {
                if (response.status === 401) {
                    setModalProps({'show': true, 'text': {'title': 'Not logged in', 'body': 'You must be logged into to do this'}, 'link': '/'});
                    return authData;
                }else{
                    return response.json();
                }
            }).catch(error => {
                setModalProps({'show': true, 'text': {'title': 'Server error', 'body': error.toString()}});
        });
    }

    useEffect(() => {
        setModal(<ModalView show={modalProps['show']} text={modalProps['text']} link={modalProps['link']} modalClose={modalClose}/>);
    }, [modalProps]);

    function applyForPermissions(permission){
        let body = {'action': 'apply', 'app': permission}
        callUrl(ACTION_URL, body).then(
            function(response){
                callUrl(AUTH_URL, null).then(
                    function(r){
                        setAuthData(r);
                    }
                )
            }
        )
    }

    function invokeAction(username, action, entity){
        let body = {'action': action, 'username': username, 'entity': entity}
        callUrl(ACTION_URL, body).then(
            function(response){
                callUrl(AUTH_URL, null).then(
                    function(r){
                        setAuthData(r);
                    }
                )
            }
        )
    }

    function adminTables(row){
        return <div key={row['app']}>
            <h3 style={{float:"left", color: "white"}}>{row['app']}</h3>
            <table className="table table-dark table-hover">
                <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Request Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                {row['users'].map((user_row)=>{
                    return <tr key={user_row['username']}>
                        <td>{user_row['name']}</td>
                        <td>{user_row['request_ts']}</td>
                        <td>{user_row['status']}</td>
                        <td>{user_row['admin_actions'].map((action_row)=>{
                            return <button key={action_row} style={{marginRight: '10px'}} type="button" className="btn btn-secondary btn-sm" onClick={() => invokeAction(user_row['username'], action_row, row['app'])}>{action_row}</button>
                        })}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>;
    }

    return (
        <div className="monitorRoot">
            {modal}
            <h1 style={{float:"left", color: "white"}}>My Permissions</h1>
            <table className="table table-dark table-hover">
                <thead>
                    <tr>
                        <th scope="col">App</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {authData['user_permissions'].map((row)=>{
                        return <tr key={row['app']}><td>{row['app']}</td><td>{row['state']}</td></tr>
                    })}
                </tbody>
            </table>

            <h1 style={{float:"left", color: "white"}}>Request Permissions</h1>
            <table className="table table-dark table-hover">
                <tbody>
                {authData['apply_permissions'].map((row)=>{
                    return <tr key={row}><td>{row}</td><td>
                        <button type="button" className="btn btn-secondary btn-sm" onClick={() => applyForPermissions(row)}>Apply</button></td></tr>
                })}
                </tbody>
            </table>

            <div className="monitorAdmin">
                <h1 style={{float:"left", color: "white"}}>Admin Tables</h1>
                <br/>
                <br/>
                <br/>
                <div>
                    {authData['admin_access'].map((row)=>{
                        return adminTables(row);
                    })}
                </div>
            </div>



        </div>

    );
}

export default EntitlementMonitor;
