import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import Accordion from 'react-bootstrap/Accordion';
import './HouseInfo.css';
import {BACKGROUND_COLORS, BASE_URL} from "../lib/Consts";
import {Button} from "react-bootstrap";
import InfoComponent from "./components/InfoComponent";
import Form from 'react-bootstrap/Form';

const I_BASE_URL = BASE_URL + "/reservations/infos";

function HouseInfo() {

    const ctx = useContext(ContextProvider);
    const [payload, setPayload] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [modalProps, setModalProps] = useState({'show': false, 'infoId': null, 'header': '', 'text': ''});
    const [infoModal, setInfoModal] = useState(<InfoComponent show={modalProps['show']} />);
    const [filter, setFilter] = useState(null);
    const [activeKey, setActiveKey] = useState(null);

    useEffect(() => {
        document.body.style.backgroundColor = BACKGROUND_COLORS["beach"];
        ctx.setNavBarView('beachNavBar');
        getData();
    }, []);

    useEffect(() => {
        setInfoModal(<InfoComponent show={modalProps['show']}
                                    infoId={modalProps['infoId']}
                                    header={modalProps['header']}
                                    text={modalProps['text']}
                                    modalClose={modalClose}/>);
    }, [modalProps]);

    useEffect(() => {
        // setInfoModal(<InfoComponent show={modalProps['show']}
        //                             infoId={modalProps['infoId']}
        //                             header={modalProps['header']}
        //                             text={modalProps['text']}
        //                             modalClose={modalClose}/>);
    }, [filter]);

    function getData(){
        fetch(I_BASE_URL, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => res.json()
        ).then((response) => {
            setAdmin(response['admin']);
            setPayload(response['data']);
        }).catch(error => {
            setAdmin(false);
            setPayload([{'header': 'Error', 'text': 'Could not retrieve from the server, might be down'}])
        });
    }

    function modalClose(){
        getData();
        setModalProps({'show': false, 'infoId': null, 'header': '', 'text': ''});
    }

    const addInfo = () => {
        setModalProps({'show': true, 'infoId': null, 'header': '', 'text': ''});
    }

    const editInfo = (header, text, order_id) => {
        setModalProps({'show': true, 'infoId': order_id, 'header': header, 'text': text});
    }

    return (
        <div className="houseInfoRoot">
            {infoModal}
            <div className="topControls">
                {/*<Button variant="secondary" onClick={() => addInfo()} disabled={!admin}>*/}
                {/*    Expand All*/}
                {/*</Button>*/}
                {/*<Button variant="secondary" onClick={() => addInfo()} disabled={!admin}>*/}
                {/*    Collapse All*/}
                {/*</Button>*/}
                <Form.Control type="search" placeholder="Search" className="me-2" aria-label="Search" onChange={(e) =>{setFilter(e.target.value)}}/>
            </div>
            <Accordion className="accordionClass" alwaysOpen>
                {payload.map((item,index)=>{
                    if(filter && (!item['header'].toLowerCase().includes(filter.toLowerCase()) && !item['text'].toLowerCase().includes(filter.toLowerCase()))){
                        return;
                    }
                    return (
                        <Accordion.Item eventKey={index.toString()} key={index.toString()}>
                            <Accordion.Header>{item['header']}</Accordion.Header>
                            <Accordion.Body><span style={{whiteSpace: "pre-line"}}>{item['text']}</span>
                                <br/>
                                <br/>
                                <Button variant="primary" onClick={() => editInfo(item['header'], item['text'], item['order_id'])} disabled={!admin}>
                                    Edit
                                </Button>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>
            <Button variant="primary" onClick={() => addInfo()} disabled={!admin}>
                Add Info
            </Button>
        </div>
    );
}

export default HouseInfo;
