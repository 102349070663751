import React, {useContext, useEffect, useState, useCallback} from "react";
import { useNavigate } from "react-router-dom";
import {ContextProvider} from "../lib/ContextProvider";
import LoginRequired from "../components/LoginRequired";
import './House.css';
import {BACKGROUND_COLORS, BASE_URL} from "../lib/Consts";
import {Alert, Button, Modal, Nav, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";
import WeatherCard from "./components/WeatherCard";
import Stack from 'react-bootstrap/Stack';
import BookComponent from "./components/BookComponent";
import moment from "moment/moment";
import Carousel from 'react-bootstrap/Carousel';

const R_BASE_URL = BASE_URL + "/reservations";
const AUTH_URL = BASE_URL + "/auth/reservations";

function House() {

    const ctx = useContext(ContextProvider);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState({'title': 'Error', 'body': 'No error message set'});
    const [modalCloseCallback, setModalCloseCallback] = useState(null);

    const handleClose = () => {
        setShowModal(false);
        if (modalCloseCallback) {
            navigate(modalCloseCallback);
        }
    }
    useEffect(() => {
        ctx.setNavBarView('beachNavBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["beach"];
        // Determine if the user is allowed to advance here, if logged in and no permissions then block
        fetch(AUTH_URL, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                if (response.status === 401) {
                    setModalText({'title': 'Not logged in',
                        'body': 'You must be logged into to do this'});
                    setShowModal(true);
                    ctx.setNavBarView('liamPortfolioBar');
                }
                else if (response.status === 403) {
                    setModalText({'title': 'Unauthorized',
                        'body': 'You do not have access to this app, please apply here.'});
                    setModalCloseCallback('/');
                    setShowModal(true);
                    ctx.setNavBarView('liamPortfolioBar');
                }
            })
            .catch(error => {
                serverError(error)
                ctx.setNavBarView('liamPortfolioBar');
            });
    }, []);

    function serverError(error) {
        setModalText({'title': 'Server error', 'body': error.toString()});
        setShowModal(true);
    }

    function modalBodyView() {
        let view = (<p>{modalText['body']}</p>);
        if (modalText.title.toLowerCase().includes("not logged in")) {
            view = (
                <div>
                    <p>{modalText['body']}</p>
                    <Link to="/login">Login here</Link>
                </div>
            );
        }
        else if (modalText.title.toLowerCase().includes("authorization")) {

        }
        else if (modalText.title.toLowerCase().includes("error")) {
            view = (
                <Alert variant="danger">
                    <Alert.Heading>Oh Snap!</Alert.Heading>
                    <p>
                        Looks like there is a problem with the server. Please be patient until fixed.
                    </p>
                </Alert>
            );
        }
        return view
    }

    // This might cause re-rendering issues, might have to refactor
    if(ctx.username === null){
        return (<div className="root">
            {LoginRequired()}
        </div>)
    }
    return (
        <div className="houseRoot">
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalText['title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBodyView()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="houseContent">

                <Carousel>
                    <Carousel.Item style={{height: "100%"}}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + "/images/beach/beach_stock1.jpeg"}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item  style={{height: "100%"}}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + "/images/beach/house/20220713_181449.jpg"}
                            alt="Second slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item  style={{height: "100%"}}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + "/images/beach/house/20220713_181530.jpg"}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>

                    <div className="button">
                        <a href="/beach/reserve">
                        <div className="button-content">
                            Book Now
                        </div>
                        </a>
                    </div>


                    <div className="button2">
                        <a href="/beach/house">
                        <div className="button-content2">
                            House Info
                        </div>
                        </a>
                    </div>

            </div>


            {/*<Row xs={1} md={2}>*/}
            {/*    <div className="card-group" style={{width: "100%", zIndex:"1"}}>*/}
            {/*        <div className="card" style={{height: "200px", margin: "15px", padding: "1px"}}>*/}
            {/*            <div className="card-body"style={{overflow: "scroll"}}>*/}
            {/*                <h5 className="card-title">Upcoming Visits</h5>*/}
            {/*                <ul className="list-group list-group-flush">*/}
            {/*                    {upcomingVists.map((value) => {*/}
            {/*                        return <li key={value['id']} className="list-group-item">{value['start'].split('T')[0]} to {value['end'].split('T')[0]}</li>*/}
            {/*                    })}*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="card" style={{height: "200px", margin: "15px", padding: "1px"}}>*/}
            {/*            <img src={process.env.PUBLIC_URL + "/images/beach/calendar_image.png"} className="card-img-top2" alt="..."/>*/}
            {/*            <div className="card-img-overlay">*/}
            {/*                <Link to="/beach/reserve" className="stretched-link"/>*/}
            {/*                <div className="card-img-overlay">*/}
            {/*                    <h1 className="card-title" style={{color:"white", fontSize: "5vmin", backgroundColor: 'rgba(115,153,231,.9)'}}>Book Now</h1>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Row>*/}





            {/*<Row xs={1} md={2} lg={3}>*/}
            {/*    <div className="card-group" style={{width: "100%"}}>*/}
            {/*        <div className="card" style={{height: "150px", margin: "15px", padding: "1px"}}>*/}
            {/*            <img src={process.env.PUBLIC_URL + "/images/beach/mail_image.png"} className="card-img-top" alt="..."/>*/}
            {/*            <div className="card-img-overlay">*/}
            {/*                <h5 className="card-title" style={{color:"white", fontSize: "5vmin"}}>Messages</h5>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <div className="card" style={{height: "150px", margin: "15px", padding: "1px", overflow: "hidden"}}>*/}
            {/*            <img src={process.env.PUBLIC_URL + "/images/beach/TrafficBackground.png"} className="card-img-top" alt="..."/>*/}
            {/*            <div className="card-img-overlay">*/}
            {/*                <h1 className="card-title" style={{color:"white", fontSize: "5vmin", backgroundColor: 'rgba(115,153,231,.9)'}}>Traffic</h1>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <div className="card" style={{height: "150px", margin: "15px", padding: "1px", overflow: "hidden", color:"white"}}>*/}
            {/*            <img src={process.env.PUBLIC_URL + "/images/weather/26302.jpg"} style={{height: "100%", wdith:"100%", overflow:"hidden"}} className="card-img-top" alt="..."/>*/}
            {/*            <div className="card-img-overlay">*/}
            {/*                <div style={{float:"left", fontFamily:"sans-serif"}} >*/}
            {/*                    {weather['location']['name']}, {weather['location']['region']}*/}
            {/*                </div>*/}
            {/*                <div style={{float:"right", fontFamily:"sans-serif"}} >*/}
            {/*                    Full Forecast*/}
            {/*                </div>*/}
            {/*                <div style={{float:"left", width:"100%"}} >*/}
            {/*                    {weatherCards()}*/}
            {/*                </div>*/}
            {/*            </div>*/}



            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Row>*/}



            {/*<div className="card" style={{height: "200px", margin: "15px", padding: "1px"}}>*/}
            {/*    <div className="card-body">*/}
            {/*        <h5 className="card-title">Recent Reviews</h5>*/}
            {/*        <ul className="list-group list-group-flush">*/}
            {/*            <li className="list-group-item">Cras justo odio</li>*/}
            {/*            <li className="list-group-item">Dapibus ac facilisis in</li>*/}
            {/*            <li className="list-group-item">Vestibulum at eros</li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<Row xs={1} md={2} lg={3}>*/}
            {/*    <div className="card-group" style={{width: "100%"}}>*/}
            {/*        <div className="card" style={{height: "150px", margin: "15px", padding: "1px"}}>*/}
            {/*            <img src={process.env.PUBLIC_URL + "/images/beach/mail_image.png"} className="card-img-top" alt="..."/>*/}
            {/*            <div className="card-img-overlay">*/}
            {/*                <h5 className="card-title" style={{color:"white", fontSize: "5vmin"}}>House Info</h5>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="card" style={{height: "150px", margin: "15px", padding: "1px"}}>*/}
            {/*            <img src={process.env.PUBLIC_URL + "/images/beach/mail_image.png"} className="card-img-top" alt="..."/>*/}
            {/*            <div className="card-img-overlay">*/}
            {/*                <h5 className="card-title" style={{color:"white", fontSize: "5vmin"}}>Things To Do</h5>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Row>*/}



        </div>
    );
}

export default House;
