import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../../lib/ContextProvider";
import {Alert, Button, Modal, Nav, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import './BookComponent.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import {BASE_URL} from "../../lib/Consts";

const PAYLOAD = {
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
    }
}

const RESERVATION_URL = BASE_URL + "/reservations";

function BookComponent(props) {

    const [reservationId, serReservationId] = useState(props['reservationId']);
    const [start, setStart] = useState(props['start']);
    const [end, setEnd] = useState(props['end']);
    const [startObj, setStartObj] = useState(new Date());
    const [endObj, setEndObj] = useState(new Date());
    // const [modalProps, setModalProps] = useState({'show': false, 'text': {'title': 'Error', 'body': 'No error message set'}});
    const [reservationData, setReservationData] = useState({'name': '', 'guestNumber': 1, 'owner': false, 'private': false, 'confirm_text': 'Save Update'});

    const ctx = useContext(ContextProvider);

    useEffect(() => {
        if(props.end){
            let end = moment(props.end);
            if (end){
                end = end.subtract(5, 'seconds');
            }
            setStartObj(moment(props.start).toDate());
            setEndObj(end.toDate());
        }

        // Adding in user data and conflicts
        let targetUrl = RESERVATION_URL
        if(props.reservationId){
            targetUrl = targetUrl + "/" + props.reservationId;
            serReservationId(props.reservationId);
        }
        else{
            targetUrl = targetUrl + "/new?start=" + moment(props.start) + "&end=" + moment(props.end);
        }
        if (props.show){
            callUrl(targetUrl, 'GET').then(
                function(response){
                    if(response['start']){
                        setStart(response['start']);
                        setEnd(response['end']);
                    }
                    setReservationData(response);
                }
            );
        }
    }, [props.show]);

    useEffect(() => {
    }, [reservationData]);

    function callUrl(url, method, body){
        let payload = PAYLOAD
        payload['method'] = method

        if (body){
            payload['body'] = JSON.stringify(body)
        }
        else{
            payload['body'] = null
        }

        return fetch(url, payload)
            .then((response) => {
                if (response.status === 401) {
                    // setModalProps({'show': true, 'text': {'title': 'Not logged in', 'body': 'You must be logged into to do this'}, 'link': '/'});
                    return {};
                }else{
                    return response.json();
                }
            }).catch(error => {
                // setModalProps({'show': true, 'text': {'title': 'Server error', 'body': error.toString()}});
            });
    }

    useEffect(() => {
        setStartObj(moment(start).toDate());
    }, [start]);

    useEffect(() => {
        setEndObj(moment(end).toDate());
    }, [end]);

    const handleClose = () => {
        props['modalClose']();
    }

    const updateReservationStatus = (action) => {
        callUrl(RESERVATION_URL + "/" + props.reservationId, 'POST', {'action': action}).then(
            function(response){
                props['modalClose']();
            }
        )
    }

    const deleteBooking = () => {
        serReservationId('');
        callUrl(RESERVATION_URL + "/" + props.reservationId, 'DELETE').then(
            function(response){
                props['modalClose']();
            }
        )
    }

    const sendUpdate = () => {
        let body = {'start': startObj.toDateString(), 'end': endObj.toDateString(), 'reservation_data': reservationData};
        let method = 'PUT'
        let url = RESERVATION_URL;
        if(props.reservationId){
            // Update existing reservation
            method = 'POST'
            url = url + "/" + props.reservationId;
            body['action'] = 'update';
        }
        callUrl(url, method, body).then(
            function(response){
                props['modalClose']();
            }
        )
    }

    const setStartDate = (d) => {
        setStart(d);
    }

    const setEndDate = (d) => {
        setEnd(d);
    }

    return (
        <div>
            <Modal dialogClassName="blah" size='lg' show={props['show']} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reservation Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row xs={1} md={2}>
                        <div className="cardBorder">
                            <div style={{width: "100%", display: "flex"}}>
                                <h4 style={{float: "left", width:"50%"}}>Start Date</h4>
                                <DatePicker className={"vertical-center"} style={{float: "right", width:"50%"}} selected={startObj} onChange={(date) => setStartDate(date)} />
                            </div>

                        </div>
                        <div className="cardBorder">
                            <div style={{width: "100%", display: "flex"}}>
                                <h4 style={{float: "left", width:"50%"}}>End Date</h4>
                                <DatePicker className={"vertical-center"} style={{float: "right", width:"50%"}} selected={endObj} onChange={(date) => setEndDate(date)} />
                            </div>

                        </div>
                    </Row>
                    <Row xs={1} md={2}>
                        <div className="cardBorder">
                            <div style={{width: "100%", display: "flex"}}>
                                <h4 style={{float: "left", width:"50%"}}>Owner</h4>
                                <h4 style={{float: "left", width:"50%"}}>{reservationData['name']}</h4>
                            </div>
                        </div>

                        <div className="cardBorder">
                            <div style={{width: "100%", display: "flex"}}>
                                <h4 style={{float: "left", width:"100%"}}>Private</h4>
                                <input className="form-check-input" style={{float: "right"}} type="checkbox"
                                       checked={reservationData['private']}
                                       onChange={e => setReservationData({...reservationData, private: !reservationData['private']})}
                                       id="flexCheckDefault"/>
                            </div>
                        </div>

                        <div className="cardBorder">
                            <div style={{width: "100%", display: "flex"}}>
                                <h4 style={{float: "left", width:"100%"}}># of Guests</h4>
                                <input style={{float: "right", width:"100%"}} type="number"
                                       placeholder="1" name="text1"
                                       onChange={e => setReservationData({...reservationData, guestNumber:e.target.value})}
                                       value={reservationData['guestNumber']}/>
                            </div>
                        </div>

                        <div className="cardBorder">
                            <div style={{width: "100%", display: "flex"}}>
                                <h4 style={{float: "left", width:"100%"}}>ID</h4>
                                <h4 style={{float: "right", width:"100%"}}>{reservationId}</h4>
                            </div>
                        </div>

                        <div className="cardBorder">
                            <div style={{width: "100%", display: "flex"}}>
                                <h4 style={{float: "left", width:"100%"}}>Status</h4>
                                <h4 style={{float: "right", width:"100%"}}>{reservationData['status']}</h4>
                            </div>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => updateReservationStatus('approve')} disabled={!props.admin}>
                        Approve
                    </Button>
                    <Button variant="primary" onClick={() => updateReservationStatus('reject')} disabled={!props.admin}>
                        Reject
                    </Button>
                    <Button variant="primary" onClick={deleteBooking} disabled={!reservationData['owner'] || reservationData['confirm_text'] == 'Reserve'}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={sendUpdate} disabled={!reservationData['owner']}>
                        {reservationData['confirm_text']}
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
}

export default BookComponent;