import React, {useContext, useEffect, useState} from "react";
import {useNavigate, Link} from "react-router-dom";
import {Alert, Button, Card, Col, FloatingLabel, Form, Modal, Row, Spinner} from "react-bootstrap";
import './Login.css';
import {BASE_URL, BACKGROUND_COLORS} from "../lib/Consts";
import {ContextProvider} from "../lib/ContextProvider";

function CustomModal(props) {

    function footerStyle() {
        console.log(props['footer'])
        if (!props['footer']){
            // Show default footer
            return (<div/>);
        }
        else if(props['footer'] === 'okFooter'){
            return(
                <Button variant="primary" onClick={props['onClose']}>
                    Okay
                </Button>
            );
        }
    }

    return (
        <Modal show={props['show']} onHide={props['onClose']}>
            <Modal.Header closeButton>
                <Modal.Title>{props['title']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props['body']}
            </Modal.Body>
            <Modal.Footer>
                {footerStyle()}
            </Modal.Footer>
        </Modal>
    );
}

export default CustomModal;