import React, {useContext, useEffect} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import { GoogleMap, useJsApiLoader, TrafficLayer } from '@react-google-maps/api';

const containerStyle = {
    width: '90%',
    height: '400px'
};

const google = window.google = window.google ? window.google : {};

function Traffic() {

    const ctx = useContext(ContextProvider);

    useEffect(() => {
        document.body.style.backgroundColor = '#93d7ca';

    }, []);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCO0XXPC1K0ybrvcYXqpn3x7vUSm9tTTVU"
    })

    const [map, setMap] = React.useState(null)
    const [zoomLevel, setZoomLevel] = React.useState(8);
    const [center, setCenter] = React.useState({
        lat: 40.50677,
        lng: -74.26542
    });

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new google.maps.LatLngBounds(center);
        setZoomLevel(8);
        setMap(map);
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    function MapView() {
        return isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoomLevel}
                defaultZoom={8}
                onLoad={onLoad}
                onUnmount={onUnmount}

            >
                <TrafficLayer zoom={8} autoUpdate />
            </GoogleMap>
        ) : <></>
    }

    return (
        <div className="root">
            {MapView()}
        </div>

    );
}

export default Traffic;
