import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import './Parking.css';
import {BASE_URL, BACKGROUND_COLORS} from "../lib/Consts";

const RESTROOM_URL = BASE_URL + "/poop";

function Restroom() {

    const ctx = useContext(ContextProvider);
    const [payload, setPayload] = useState("");

    useEffect(() => {
        ctx.setNavBarView('liamPortfolioBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["portfolio"];

        fetch(RESTROOM_URL, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => res.json()
        ).then((response) => {
            setPayload(response)
        }).catch(error => {
            setPayload({'current': 'Could not retrieve data from server', 'change_in': 'Sorry - need to just use drop the kids off at the pool'})
            setPayload('Could not retrieve data from server; Sorry - need to just use drop the kids off at the pool')
        });
    }, []);

    return (
        <div className="rootParking">
            <h3>{payload}</h3>
        </div>
    );
}

export default Restroom;
