import React, {useEffect, useState} from "react";
import {Button, FormCheck, Modal} from "react-bootstrap";
import {BASE_URL} from "../../lib/Consts";
import ReactImageUploading from "react-images-uploading";
import Card from "react-bootstrap/Card";

const R_BASE_URL = BASE_URL + "/reservations/gallery";
const PAYLOAD = {
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
        // "Content-Type": "multipart/form-data",
    }
}

function GalleryComponent(props) {

    const [images, setImages] = useState(null);
    const [imageKey, setImageKey] = useState(null);

    const handleClose = () => {
        props['modalClose']();
    }

    useEffect(() => {
        setImageKey(props['photoKey'])
    }, [props]);

    const deletePhoto = (photoKey) => {
        if (window.confirm("Are you sure you want to delete this review?")) {
            let reqPayload = PAYLOAD;
            reqPayload['method'] = 'DELETE';
            fetch(R_BASE_URL + '/' + photoKey, reqPayload).then(() => handleClose());
        }
    };

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);
    };

    const submitImages = () => {
        let payload = PAYLOAD;
        let url = R_BASE_URL;
        payload['body'] = JSON.stringify({'files': images});
        payload['method'] = 'POST';
        fetch(url, payload)
            .then((response) => {
                return response.json();
            }).catch(error => {
        });
        handleClose();
    };

    function view(){
        if (imageKey){
            return (
                <Modal dialogClassName="blah" size='lg' show={props['show']} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{width: '100%'}}>Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img style={{width: '100%'}} src={props['value']}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="btn btn-secondary btn-sm" onClick={() => deletePhoto(props['photoKey'])}>
                            Delete
                        </Button>
                        <Button variant="btn btn-secondary btn-sm" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }
        else{
            return (
                <Modal dialogClassName="blah" size='lg' show={props['show']} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{width: '75%'}}>{props['photoId'] ? 'Photo Options' : 'Add Photos'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={10}
                            dataURLKey="data_url"
                        >
                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                              }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                    <div className="mainbtndiv" style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                        <Button className="btn btn-primary"
                                                style={isDragging ? { color: 'red', width: '15%' } : {width: '15%'}}
                                                onClick={onImageUpload}
                                                {...dragProps}
                                        >
                                            Click or Drop here
                                        </Button>
                                        <Button className="btn btn-danger" style={{width: '15%'}} onClick={onImageRemoveAll}>Remove all images</Button>
                                        <Button onClick={submitImages} style={{width: '15%'}}>Save</Button>
                                    </div>
                                    {imageList.map((image, index) => (
                                        <div key={index} className="image-item mt-5 mb-5 mr-5" style={{width: '50%', marginLeft:'auto', marginRight:'auto'}}>
                                            <img src={image['data_url']}  style={{width: '100%'}}/>
                                            <div className="image-item__btn-wrapper">
                                                <Button className="btn btn-primary" onClick={() => onImageUpdate(index)}>Update</Button>
                                                <Button className="btn btn-danger" onClick={() => onImageRemove(index)}>Remove</Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </ReactImageUploading>
                    </Modal.Body>





                    {/*<Modal.Body>*/}
                    {/*    <Rating*/}
                    {/*        name="size-large"*/}
                    {/*        value={ratingValue}*/}
                    {/*        size="large"*/}
                    {/*        onChange={(event, newValue) => {*/}
                    {/*            setRatingValue(newValue);*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    <Form.Control size="lg" type="text" placeholder="Summary Here" onChange={(e) =>{setHeader(e.target.value)}} value={header}/>*/}
                    {/*    <br/>*/}
                    {/*    <Form.Control as="textarea" placeholder="Details Here" rows={6} onChange={(e) =>{setText(e.target.value)}} value={text}/>*/}

                    {/*</Modal.Body>*/}
                    {/*<Modal.Footer>*/}
                    {/*    <Button variant="primary" onClick={() => sendUpdate(props['reviewId'] ? 'update' : 'create')}>*/}
                    {/*        {props['infoId'] ? 'Update' : 'Create'}*/}
                    {/*    </Button>*/}
                    {/*    <Button variant="primary" onClick={handleClose}>*/}
                    {/*        Close*/}
                    {/*    </Button>*/}
                    {/*</Modal.Footer>*/}
                </Modal>
            )
        }
    }

    return (view());

}

export default GalleryComponent;