import React, {useContext, useEffect} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import './LoginRequired.css';
import {Button, Card, Row} from "react-bootstrap";
import {BACKGROUND_COLORS} from "../lib/Consts";

function LoginRequired() {

    const ctx = useContext(ContextProvider);

    useEffect(() => {
        ctx.setNavBarView('liamPortfolioBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["portfolio"];
    }, []);

    return (
        <div className={"rootLoginRequired"}>
            <div className="row">
                <Card style={{ width: '18rem' }}>
                    <Card.Img className="loginCardImg" variant="top" src={process.env.PUBLIC_URL + "/images/general/noentry.png"} />
                    <Card.Body>
                        <div style={{fontSize: '18px'}}>Login required to visit this</div>
                        <Row className="justify-content-md-center">
                            <Button href="/register" md="4" style={{marginTop: '30px'}}>
                                Register
                            </Button>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Button href="/login" md="4" style={{marginTop: '30px'}}>
                                Login
                            </Button>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </div>

    );
}

export default LoginRequired;
