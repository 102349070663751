import React, {useEffect, useState} from "react";
import {Button, FormCheck, Modal} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {BACKGROUND_COLORS, BASE_URL} from "../../lib/Consts";

const R_BASE_URL = BASE_URL + "/reservations/infos";
const PAYLOAD = {
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
    }
}

function InfoComponent(props) {

    const [header, setHeader] = useState('');
    const [text, setText] = useState('');
    const [deleteEnabled, setDeleteEnabled] = useState(false);

    const handleClose = () => {
        props['modalClose']();
    }

    useEffect(() => {
        setHeader(props['header']);
        setText(props['text']);
    }, [props]);

    const sendUpdate = (event) => {
        let payload = PAYLOAD;
        let url = R_BASE_URL;
        if (event === 'create') {
            payload['method'] = 'PUT';
            payload['body'] = JSON.stringify({'header': header, 'text': text})
        }
        else {
            url = url + '/' + props['infoId'];
            payload['method'] = (event === 'delete') ? 'DELETE' : 'POST';
            payload['body'] = JSON.stringify({'header': header, 'text': text})
        }
        fetch(url, payload)
            .then((response) => {
                return response.json();
            }).catch(error => {
                console.log(error)
            });
        handleClose();
    }

    return (
        <Form>
            <Modal dialogClassName="blah" size='lg' show={props['show']} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{width: '75%'}}>{props['infoId'] ? 'Edit Section' : 'Add Section'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control size="lg" type="text" placeholder="Header Here" onChange={(e) =>{setHeader(e.target.value)}} value={header}/>
                    <br/>
                    <Form.Control as="textarea" placeholder="Text Here" rows={6} onChange={(e) =>{setText(e.target.value)}} value={text}/>
                </Modal.Body>
                <Modal.Footer>
                    Allow Delete
                    <FormCheck disabled={!props['infoId']} onClick={() => setDeleteEnabled(!deleteEnabled)}/>
                    <Button variant="danger" onClick={() => sendUpdate('delete')} disabled={!deleteEnabled}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={() => sendUpdate(props['infoId'] ? 'update' : 'create')}>
                        {props['infoId'] ? 'Update' : 'Create'}
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>
    );

}

export default InfoComponent;