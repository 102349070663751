import React, { createContext, useState, useEffect } from 'react';
import {useMediaQuery} from "react-responsive";

const ContextProvider = createContext(null);
export { ContextProvider };

export default ({ children }) => {

    let data;
    // let username = sessionStorage.getItem('liamPortfolioUsername');
    const [username, setUsername] = useState(sessionStorage.getItem('liamPortfolioUsername'));
    const [navBarView, setNavBarView] = useState(sessionStorage.getItem('liamPortfolioBar'));

    useEffect(() => {
        if(username === null){
            sessionStorage.removeItem('liamPortfolioUsername')
        }
        else{
            sessionStorage.setItem('liamPortfolioUsername', username);
        }
    }, [username]);

    useEffect(() => {
        if(navBarView === null){
            sessionStorage.removeItem('liamPortfolioBar')
        }
        else{
            sessionStorage.setItem('liamPortfolioBar', navBarView);
        }
    }, [navBarView]);

    const minWidth = useMediaQuery({
        query: '(min-width: 600px)'
    })

    data = {
        isMobile: !minWidth,
        testValue: false,
        username: username,
        navBarView: navBarView,
        setUsername,
        setNavBarView
    }

    return (
        <ContextProvider.Provider value={data}>
            {children}
        </ContextProvider.Provider>
    )
}
