import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../lib/ContextProvider";
import './Parking.css';
import {BASE_URL, BACKGROUND_COLORS} from "../lib/Consts";

const PARKING_URL = BASE_URL + "/parking";

function Parking() {

    const ctx = useContext(ContextProvider);
    const [payload, setPayload] = useState({});

    useEffect(() => {
        ctx.setNavBarView('liamPortfolioBar');
        document.body.style.backgroundColor = BACKGROUND_COLORS["portfolio"];

        fetch(PARKING_URL, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        }).then(res => res.json()
        ).then((response) => {
            setPayload(response)
        }).catch(error => {
            setPayload({'current': 'Could not retrieve data from server', 'change_in': 'Read the sign'})
        });
    }, []);

    return (
        <div className="rootParking">
            <h3>{payload['current']}</h3>
            <h3>{payload['change_in']}</h3>
        </div>
    );
}

export default Parking;
